import { navigate } from '@reach/router';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { countries, getCountryFlag, getCountryName } from '../../common/countries';
import { getCurrentCountryCode } from '../../common/helpers/countrySelection';
import { useDebug } from '../../common/hooks/useDebug';
import { DEFAULT_LANGUAGE, DEFAULT_LOCALE, useTranslations } from '../../common/translation';
import { HeadTitle } from '../../components/head-title/HeadTitle';
import { WhiteShield } from '../../components/white-shield/WhiteShield';
import { MARKET } from '../../config/browser';
import { BlankLayout } from '../blank/BlankLayout';
import {
  StyledCSBox,
  StyledCSList,
  StyledCSListItemLink,
  StyledCSLogo,
  StyledCSTitle,
} from './CountrySelectionLayout.styles';
import translations from './translations';

const countryListItem = (countryCode: string) => {
  const CountryFlagIcon = getCountryFlag(countryCode);

  return (
    <li key={`country-selection-li-${countryCode}`}>
      <StyledCSListItemLink href={`/${DEFAULT_LANGUAGE}-${countryCode.toLowerCase()}`}>
        <CountryFlagIcon />
        <span>{getCountryName(countryCode)}</span>
      </StyledCSListItemLink>
    </li>
  );
};

// noinspection JSUnusedGlobalSymbols
export default () => {
  const { t } = useTranslations(translations, DEFAULT_LOCALE);
  const [showLayout, setShowLayout] = useState<boolean>(false);

  useDebug('app:layout:CountrySelectionLayout');

  useEffect(() => {
    const countryCode = getCurrentCountryCode();
    if (countryCode) {
      /*
       * Navigation on first render fails due to absence of LocationProvider.
       * Bypass this issue by navigating as soon as possible with setTimeout.
       * The alternative of using a <Redirect /> component results in a
       * flickering country selection page.
       *
       * Reference: https://github.com/reach/router/issues/91
       */
      setTimeout(() => {
        navigate(`/${DEFAULT_LANGUAGE}-${countryCode.toLowerCase()}`, { replace: true });
      }, 0);
    } else {
      setShowLayout(true);
    }
  }, []);

  // Revisit title translation if/when LATAM is no longer the only multi-country market.
  const headTitle = t('pageTitle', 'Select Country');
  const description = t(
    'pageDescription',
    'Home of Warner Bros Movies, TV Shows and Video Games including Harry Potter, DC Comics and more!',
  );
  const seo = {
    description,
  };

  return (
    <BlankLayout locale={DEFAULT_LOCALE} seo={seo}>
      <HeadTitle title={headTitle} />
      {showLayout && (
        <>
          <StyledCSLogo>
            <WhiteShield />
          </StyledCSLogo>
          <StyledCSBox>
            <StyledCSTitle>{t('selectCountry', 'Select Country')}</StyledCSTitle>
            <StyledCSList>
              {Object.keys(countries[MARKET]).map((countryCode) => countryListItem(countryCode))}
            </StyledCSList>
          </StyledCSBox>
        </>
      )}
    </BlankLayout>
  );
};
