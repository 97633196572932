import * as React from 'react';
import { useDebug } from '../../common/hooks/useDebug';
import { LocaleProvider } from '../../common/hooks/useLocale';
import { HeadMeta } from '../../components/head-meta/HeadMeta';
import { BlankLayoutProps } from './BlankLayout.props';
import { StyledBlankLayout } from './BlankLayout.styles';

// noinspection JSUnusedGlobalSymbols
export const BlankLayout = ({
  children,
  bgColor,
  locale = 'en-US',
  seo,
}: React.PropsWithChildren<BlankLayoutProps>) => {
  const description = seo?.description || '';

  useDebug('app:layout:BlankLayout');

  return (
    <LocaleProvider locale={locale}>
      <HeadMeta locale={locale} description={description} />
      <StyledBlankLayout bgColor={bgColor} id='blank-layout'>
        {children}
      </StyledBlankLayout>
    </LocaleProvider>
  );
};
