import { rem } from 'polished';
import styled from 'styled-components';
import { mediaQueryWidth } from '../../common/breakpoints';
import { black, blue, grey3, white } from '../../common/colors';
import { MARKET } from '../../config/browser';

export const StyledCSLogo = styled.div`
  margin: ${rem(30)} 0;
  text-align: center;

  svg {
    width: ${rem(36)};
    height: ${rem(46)};
  }

  ${mediaQueryWidth({ min: 'tablet' })`
    margin: ${rem(40)} 0;

    svg {
      width: ${rem(45)};
      height: ${rem(58)};
    }
  `}
`;

export const StyledCSBox = styled.div`
  box-sizing: border-box;
  margin: 0 ${rem(20)};
  padding: ${rem(20)};
  background-color: ${white};

  ${mediaQueryWidth({ min: 'tablet' })`
    max-width: ${rem(808)};
    margin: 0 auto;
    padding: ${rem(40)};
  `}

  /* CSS Grid fallback for IE */
  @media (-ms-high-contrast: none), (-ms-high-contrast: active), @supports not (display: grid) {
    display: flex;
    flex-direction: column;
    height: ${rem(384)};
    padding: ${rem(20)} 0 ${rem(10)} ${rem(20)};

    & > h2 {
      padding: 0 ${rem(20)} 0 0;
    }

    & > ul {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      overflow: auto;

      & > li {
        margin: 0 ${rem(20)} ${rem(10)} 0;
      }
    }

    ${mediaQueryWidth({ min: 'tablet' })`
      height: ${rem(444)};
      padding: ${rem(40)} 0 ${rem(25)} ${rem(40)};

      & > h2 {
        padding: 0 ${rem(40)} 0 0;
      }

      & > ul > li {
        margin: 0 ${rem(40)} ${rem(15)} 0;
      }
    `}
  }
`;

export const StyledCSTitle = styled.h1`
  margin: 0 0 ${rem(15)} 0;
  font-size: ${rem(20)};
  line-height: ${rem(24)};
  font-weight: 800;
  text-align: center;
  color: ${black};

  ${mediaQueryWidth({ min: 'mobile' })`
    font-size: ${rem(40)};
    line-height: ${rem(49)};
  `}
`;

export const StyledCSList = styled.ul`
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: ${MARKET === 'DE' ? 'repeat(1, 1fr)' : 'repeat(9, 1fr)'};
  grid-template-columns: ${MARKET === 'DE' ? 'repeat(2, 1fr)' : 'repeat(2, 1fr)'};
  grid-row-gap: ${rem(10)};
  grid-column-gap: ${rem(20)};

  ${mediaQueryWidth({ min: 'mobile' })`
    grid-template-rows: ${MARKET === 'DE' ? 'repeat(1, 1fr)' : 'repeat(6, 1fr)'};
    grid-template-columns: ${MARKET === 'DE' ? 'repeat(2, 1fr)' : 'repeat(3, 1fr)'};
    grid-row-gap: ${rem(15)};
    grid-column-gap: ${rem(40)};
  `}
`;

export const StyledCSListItemLink = styled.a`
  display: block;
  padding: ${rem(4)} 0;
  font-size: ${rem(12)};
  line-height: ${rem(16)};
  color: ${black};

  &:hover,
  &:focus {
    color: ${blue};
    background-color: ${grey3};
    cursor: pointer;
  }

  & > * {
    vertical-align: middle;
  }

  & > svg {
    width: ${rem(16)};
    height: ${rem(10)};
    margin: 0 ${rem(6)} 0 ${rem(4)};
  }

  ${mediaQueryWidth({ min: 'mobile' })`
    padding: ${rem(8)} 0;
    font-size: ${rem(14)};
    line-height: ${rem(20)};

    & > svg {
      width: ${rem(26)};
      height: ${rem(16)};
      margin: 0 ${rem(20)} 0 ${rem(16)};
    }
  `}
`;
